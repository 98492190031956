import { combineReducers } from "redux";

import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import searchReducer from "./searchReducer";
import notifReducer from "./notifReducer";
import mortgageReducer from "./mortgageReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  search: searchReducer,
  notification: notifReducer,
  mortgage: mortgageReducer

});