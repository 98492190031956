export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_SEARCH = "SET_SEARCH";
export const LOADING_SEARCH = "LOADING_SEARCH";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_DEMANDE = "SET_DEMANDE";
export const LOADING_NOTIFICATION = "LOADING_NOTIFICATION";

export const SET_SIMULATION = "SET_SIMULATION";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const SET_CURRENT_BIEN = "SET_CURRENT_BIEN";
export const BIEN_LOADING = "BIEN_LOADING";
export const CREATE_BIEN = "CREATE_BIEN";
export const EDIT_BIEN = "EDIT_BIEN";
export const DELETE_BIEN = "DELETE_BIEN";

export const SET_CURRENT_ANNONCE = "SET_CURRENT_ANNONCE";
export const CREATE_ANNONCE = "CREATE_ANNONCE";
export const EDIT_ANNONCE = "EDIT_ANNONCE";
export const DELETE_ANNONCE = "DELETE_ANNONCE";

