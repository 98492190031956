import {
    SET_NOTIFICATION,LOADING_NOTIFICATION, SET_DEMANDE
    } from "../actions/types.js";
    const initialState = {
      number : 0,
      loading:false,
      searchisnotempty : false, 
      payload:"",
      pager : [],
      demande:false,
  
    };
     
  export default function notiffoo(state = initialState, action) {
      switch (action.type) {
        case SET_NOTIFICATION:
          return {
            ...state,
            number : action.number,
            searchisnotempty : action.searchisnotempty,
            payload: action.payload,
            pager: action.pager,
  
          };
          case LOADING_NOTIFICATION:
            return {
              ...state,
              loading : action.loading,
               };

               case SET_DEMANDE:
                return {
                  ...state,
                  demande : action.demande,
                   };
        default:
          return state;
      }
  }